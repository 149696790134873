import React from "react";

function DatabaseProject() {
  return (
    <div>
      <h3>VideoGame Rentals Database</h3>
    </div>
  );
}

export default DatabaseProject;
